<template>
  <div class="just_between search_form " :style="{minWidth:'350px'}">
    <div class="flex_1 dis_flex select_est" >
      <el-select v-model="topSelct" :class="showButtons? 'wid_160':'wid_110'">
        <el-option :label="item.label" :value="item.value" v-for="item in searchArray" :key="item.label"></el-option>
      </el-select>
      <div class="sloor"></div>
      <div class="flex_1 ">
        <div v-for="item in searchArray" :key="item.label+item.value" class=" wid_100 flex_col" v-if="topSelct===item.value">
          <el-input v-if="item.type==='input'" v-model="searchForm[item.value]"  :placeholder="$strings.please_input_place"></el-input>
          <el-select v-if="item.type==='select'" v-model="searchForm[item.value]" :clearable="!showButtons"  :placeholder="$strings.please_choose_place">
            <el-option :label="it.label" :value="it.value" v-for="it in item.valueList" :key="it.label"></el-option>
          </el-select>
          <searchSelect 
              v-if="item.type==='searchSelect'"
              class="wid_100"
              :value="searchForm[item.value]"
              :placeholder="$strings.please_input_place"
              :search="item.search"
              search-key="searchKey"
              :prop="item.prop"
              :currentProp="item.value"
              :get-keys="changeKeys"
            />
        </div>
      </div>
      <div class="sloor"></div>
      <tms-icon type="chaxun font_2 font_s_16 est_search"  @click="searchList" />
      <!-- <slot name="input"></slot> -->
    </div>
    <div class="search_right_alone border_l_1_s tms_button" v-if="showButtons">
      <el-popover
        width="486"
        placement="bottom-end"
        v-model="visible">
        <div class="flex_1 ">
         <el-form :model="searchForm" label-width="140px" class="est_form">
           <el-form-item :label="item.label"  v-for="item in searchArray" :key="item.label+item.value">
            <el-input v-if="item.type==='input'" v-model="searchForm[item.value]" :placeholder="$strings.please_input_place"></el-input>
            <el-select v-if="item.type==='select'" class="wid_100" v-model="searchForm[item.value]" :placeholder="$strings.please_choose_place">
              <el-option :label="it.label" :value="it.value" v-for="it in item.valueList" :key="it.label"></el-option>
            </el-select>
            <searchSelect 
              v-if="item.type==='searchSelect'"
              class="wid_100"
              :value="searchForm[item.value]"
              :placeholder="$strings.please_input_place"
              :search="item.search"
              search-key="searchKey"
              :prop="item.prop"
              :currentProp="item.value"
              :get-keys="changeKeys"
            />
           </el-form-item>
         </el-form>
        </div>
        <div style="text-align: right; margin-top: 20px">
          <el-button  @click="visible = false" size="mini" >{{$strings.cancel}}</el-button>
          <el-button type="primary" size="mini" @click="sureSearch">{{$strings.submit}}</el-button>
        </div>
        <el-badge :value="searchFormLength"  slot="reference" :hidden="!searchFormLength">
          <el-button  type="primary"  >{{$strings.filter}}</el-button>
        </el-badge>
        
      </el-popover>
      <el-button @click="resetSearch" class="m_l_16"> {{ $strings.reset }}</el-button>
      
      <br/>
      <div class="tabel_oprate m_t_20 " v-if="exportFlag" @click="exportFun">
        <tms-icon type="piliangdaochu" :title="$strings.all_export" />
      </div>
    </div>
  </div>
</template>

<script>
import icon from '../icon/index.vue'
import searchSelect from '../searchSelect/index.vue'
export default {
  name: 'searchAlone',
  props: {
    search: {
      type: Function,
      default:()=>{}
    },
    reset: {
      type: Function,
      default:()=>{}
    },
    exportFun: {
      type: Function,
      default:()=>{}
    },
    exportFlag: {
      type: Boolean,
      default:false
    },
    searchArray: {
      type: Array,
      default:()=>[]
    },
    showButtons: {
      type: Boolean,
      default:true
    }
    
  },
  data() {
    return {
      topSelct: this.searchArray[0].value,
      searchForm: {},
      visible:false
    }
  },
  components: {
    icon,
    searchSelect
  },
  computed: {
    searchFormLength(){
      let number = 0
      for(let key in this.searchForm){
        if(String(this.searchForm[key])!==''){
          number++
        }
      }
      return number
    }
  },
  methods: { 
    searchList() {
      this.search&&this.search()
    },
    resetSearch() {
      for(let key in this.searchForm){
        this.searchForm[key] = ''
      }
      this.searchList()
    },
    showAll(){
      this.visible = true
    },
    sureSearch(){
      this.visible = false
      this.searchList()
    },
    changeKeys(value, prop) {
      this.$set(this.searchForm, prop, value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';
@import '@/styles/layout.scss';
.search_form{
  .el-form-item--small.el-form-item{
    margin-bottom: 0px !important;
  }
  .el-form{
      margin-top: -10px;
  }
}
.est_form{
  border-bottom: 1px solid ;
  @extend .border_3;
}

.search_right_alone{
  @extend .just_end;
  padding-left: 18px;
  @extend .border_3;
  border-left-width: 2px !important;
  margin-left: 16px;
  flex-wrap: wrap;
  min-width: 256px;
  
}
.el-popover{
  padding: 20px !important;
}
.select_est:focus-within{
  border-color: #165DFF !important;
}
.select_est{
  border: 1px solid ;
  border-radius: 2px;
  @extend .border_3;
}
.sloor{
  @extend .bac_table;
  height: 22px;
  width: 2px;
  margin: 5px 0;
}
.est_search{
  padding: 7px 18px;
}
.tms_button{
  .el-button--small{
    font-size: 14px;
    border: 0;
    padding: 5px 18px;
    height: 32px;
    span{
      height: 22px;
      display: inline-block;
      line-height: 22px;
      i{
        font-size: 14px;
        margin-right: 5px;
      }
    }
  }
  .el-button--default {
    @extend .font_2;
    @extend .bac_1;
  }
  .el-button--primary i{
    @extend .font_5;
  }
}
</style>
