var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      attrs: {
        multiple: _vm.multiple,
        filterable: "",
        clearable: _vm.multiple,
        remote: "",
        "reserve-keyword": "",
        placeholder: _vm.$strings.please_choose_place,
        "remote-method": _vm.remoteMethod,
        loading: _vm.loading,
        disabled: _vm.disabled,
      },
      on: {
        focus: function ($event) {
          return _vm.remoteMethod("")
        },
      },
      model: {
        value: _vm.roles,
        callback: function ($$v) {
          _vm.roles = $$v
        },
        expression: "roles",
      },
    },
    _vm._l(_vm.options, function (item) {
      return _c("el-option", {
        key: item[_vm.prop.value],
        attrs: {
          disabled: item.defaultFlag,
          label: item[_vm.prop.name],
          value: item[_vm.prop.value],
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }