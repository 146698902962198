var render, staticRenderFns
import script from "./render-column.vue?vue&type=script&lang=js"
export * from "./render-column.vue?vue&type=script&lang=js"
import style0 from "./render-column.vue?vue&type=style&index=0&id=1e7dec06&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/tms_web_ui_8wmB/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1e7dec06')) {
      api.createRecord('1e7dec06', component.options)
    } else {
      api.reload('1e7dec06', component.options)
    }
    
  }
}
component.options.__file = "src/components/globalComponents/table/render-column.vue"
export default component.exports